import React from "react"

export default ({ width = "100%", height = "100%" }) => (
  <svg
    id="fe1bd44d-772d-49e6-8ede-d714f2b892c4"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 872.24627 646.29397"
    height={height}
    width={width}
  >
    <circle cx="730.18162" cy="600.55246" r="2.05936" fill="#7d1111" />
    <path
      d="M894.92774,725.54052a2.05662,2.05662,0,0,1-2.25337,3.38663,2.05759,2.05759,0,1,0,2.25337-3.38663Z"
      transform="translate(-163.87686 -126.85301)"
      opacity="0.2"
      style={{ isolation: "isolate" }}
    />
    <ellipse
      cx="783.12431"
      cy="602.78343"
      rx="5.06259"
      ry="2.05936"
      fill="#3f3d56"
    />
    <path
      d="M947.00119,727.57708q-.26072,0-.51484.01055c2.55416.10494,4.54775.98215,4.54775,2.04881s-1.99359,1.94386-4.54775,2.04881q.254.01044.51484.01055c2.796,0,5.06259-.922,5.06259-2.05936S949.79717,727.57708,947.00119,727.57708Z"
      transform="translate(-163.87686 -126.85301)"
      opacity="0.2"
      style={{ isolation: "isolate" }}
    />
    <path
      d="M953.6083,729.63644H928.20953v-2.74581a.51484.51484,0,0,0-.51483-.51484H913.62241a.51484.51484,0,0,0-.51484.51484v2.74581H898.34883v-1.71613a.51484.51484,0,0,0-.51484-.51484h-7.551a.51484.51484,0,0,0-.51484.51484v1.71613h-2.05936a.51415.51415,0,0,0-.51484.51347v33.80918a.51415.51415,0,0,0,.51346.51484H953.6083a.51416.51416,0,0,0,.51484-.51347V730.15128a.51416.51416,0,0,0-.51347-.51484Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#3f3d56"
    />
    <rect
      x="723.31709"
      y="616.85571"
      width="66.92917"
      height="6.5213"
      fill="#7d1111"
    />
    <circle cx="756.78168" cy="620.11637" r="13.04261" fill="#7d1111" />
    <circle cx="756.78168" cy="620.11637" r="9.70613" fill="#3f3d56" />
    <path
      d="M928.20953,746.96938a7.50341,7.50341,0,0,1-.61266,2.98092,7.40233,7.40233,0,0,1-.81516,1.4364,7.55071,7.55071,0,1,1-10.54049-10.54048,7.40018,7.40018,0,0,1,1.4364-.81516,7.55185,7.55185,0,0,1,10.5319,6.93832Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#fff"
    />
    <rect x="110.24618" width="2" height="187" fill="#e6e6e6" />
    <path
      d="M165.084,230.20315a1.20843,1.20843,0,0,0-1.20712,1.20712V389.20353a1.20826,1.20826,0,0,0,1.20712,1.20711H384.916a1.20826,1.20826,0,0,0,1.20712-1.20711V231.41027a1.20843,1.20843,0,0,0-1.20712-1.20712Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#e6e6e6"
    />
    <path
      d="M372.7781,243.22443V355.36114a2.82227,2.82227,0,0,1-2.82133,2.82321h-190.361a2.37971,2.37971,0,0,1-.4046-.03147,2.8206,2.8206,0,0,1-2.41861-2.79174V243.22443a2.82225,2.82225,0,0,1,2.8213-2.82318h190.361a2.82226,2.82226,0,0,1,2.82321,2.82132Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#fff"
    />
    <path
      d="M302.42741,358.6339H180.922a2.47691,2.47691,0,0,1-.45406-.036l57.46648-99.54021a4.02364,4.02364,0,0,1,7.00406,0l38.56722,66.7992,1.84768,3.19635Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#e6e6e6"
    />
    <polygon
      points="138.55 231.781 97.066 231.781 117.43 202.2 118.896 200.069 119.629 199.004 121.477 202.2 138.55 231.781"
      opacity="0.2"
      style={{ isolation: "isolate" }}
    />
    <path
      d="M370.08076,358.6339H264.98852l20.36482-29.58064,1.46553-2.13091,26.53718-38.54925a5.36966,5.36966,0,0,1,7.97059-.47652,4.79728,4.79728,0,0,1,.38215.47652Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#e6e6e6"
    />
    <circle cx="111.24627" cy="106.99997" r="4" fill="#fff" />
    <rect x="435.24618" width="2" height="187" fill="#e6e6e6" />
    <path
      d="M490.084,230.20315a1.20843,1.20843,0,0,0-1.20712,1.20712V389.20353a1.20826,1.20826,0,0,0,1.20712,1.20711H709.916a1.20826,1.20826,0,0,0,1.20712-1.20711V231.41027a1.20843,1.20843,0,0,0-1.20712-1.20712Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#e6e6e6"
    />
    <path
      d="M697.7781,243.22443V355.36114a2.82227,2.82227,0,0,1-2.82133,2.82321h-190.361a2.37971,2.37971,0,0,1-.4046-.03147,2.8206,2.8206,0,0,1-2.41861-2.79174V243.22443a2.82225,2.82225,0,0,1,2.8213-2.82318h190.361a2.82226,2.82226,0,0,1,2.82321,2.82132Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#fff"
    />
    <path
      d="M627.42741,358.6339H505.922a2.47691,2.47691,0,0,1-.45406-.036l57.46648-99.54021a4.02364,4.02364,0,0,1,7.00406,0l38.56722,66.7992,1.84768,3.19635Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#7d1111"
    />
    <polygon
      points="463.55 231.781 422.066 231.781 442.43 202.2 443.896 200.069 444.629 199.004 446.477 202.2 463.55 231.781"
      opacity="0.2"
      style={{ isolation: "isolate" }}
    />
    <path
      d="M695.08076,358.6339H589.98852l20.36482-29.58064,1.46553-2.13091,26.53718-38.54925a5.36966,5.36966,0,0,1,7.97059-.47652,4.79728,4.79728,0,0,1,.38215.47652Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#7d1111"
    />
    <circle cx="436.24627" cy="106.99997" r="4" fill="#fff" />
    <rect x="760.24618" width="2" height="187" fill="#e6e6e6" />
    <path
      d="M815.084,230.20315a1.20843,1.20843,0,0,0-1.20712,1.20712V389.20353a1.20826,1.20826,0,0,0,1.20712,1.20711h219.832a1.20826,1.20826,0,0,0,1.20712-1.20711V231.41027a1.20843,1.20843,0,0,0-1.20712-1.20712Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#e6e6e6"
    />
    <path
      d="M1022.7781,243.22443V355.36114a2.82227,2.82227,0,0,1-2.82133,2.82321h-190.361a2.37971,2.37971,0,0,1-.4046-.03147,2.8206,2.8206,0,0,1-2.41861-2.79174V243.22443a2.82225,2.82225,0,0,1,2.8213-2.82318h190.361a2.82226,2.82226,0,0,1,2.82321,2.82132Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#fff"
    />
    <path
      d="M952.42741,358.6339H830.922a2.47691,2.47691,0,0,1-.45406-.036l57.46648-99.54021a4.02364,4.02364,0,0,1,7.00406,0l38.56722,66.7992,1.84768,3.19635Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#e6e6e6"
    />
    <polygon
      points="788.55 231.781 747.066 231.781 767.43 202.2 768.896 200.069 769.629 199.004 771.477 202.2 788.55 231.781"
      opacity="0.2"
      style={{ isolation: "isolate" }}
    />
    <path
      d="M1020.08076,358.6339H914.98852l20.36482-29.58064,1.46553-2.13091,26.53718-38.54925a5.36966,5.36966,0,0,1,7.97059-.47652,4.79728,4.79728,0,0,1,.38215.47652Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#e6e6e6"
    />
    <circle cx="761.24627" cy="106.99997" r="4" fill="#fff" />
    <path
      d="M848.58252,539.903l1.8678,12.7633a11.28607,11.28607,0,0,1-12.72681,12.812h0a11.28606,11.28606,0,0,1-8.96793-15.24526l6.33663-16.40067-3.37259-110.62084,19.561-7.41969Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#a0616a"
    />
    <circle cx="629.39524" cy="202.60054" r="26.98069" fill="#a0616a" />
    <path
      d="M825.64893,380.04235l-49.91428-2.02355c5.54155-12.136,7.73983-23.87513,5.39614-35.0749h33.05135C812.44414,353.506,817.24118,366.18842,825.64893,380.04235Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#a0616a"
    />
    <path
      d="M817.55473,485.26705l-73.52239,5.39614,2.67255-41.54634c-8.4733-20.15337-7.11982-34.70642,2.85286-44.34749l.90294-14.0371a21.53089,21.53089,0,0,1,15.90722-19.41335l12.73933-3.41787c16.49521,9.66291,29.64467,9.53114,39.122-1.349l15.1619,7.76585a20.34026,20.34026,0,0,1,10.94321,20.34991Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#7d1111"
    />
    <path
      d="M704.91034,489.31415l-20.75307,10.89537a8.946,8.946,0,0,1-10.87428-2.0108h0a8.946,8.946,0,0,1,4.92314-14.6745l14.56292-2.97878L737.96169,463.008l7.41969-52.61235,20.91,5.39614-4.0471,62.05559Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#a0616a"
    />
    <path
      d="M852.62963,419.16436,829.696,432.6547l-1.349-53.96138,3.95416-1.349a20.32848,20.32848,0,0,1,20.32847,20.32847Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#7d1111"
    />
    <path
      d="M836.77847,750.01509l-19.50122-3.05089c-3.93743-66.6281-11.86861-128.8299-26.0287-184.12766-32.74538,43.39091-60.1954,107.34133-83.64014,186.84129l-22.93359-6.07066,60.032-261.71271,71.49883-1.349C845.44691,520.5818,836.88633,644.23271,836.77847,750.01509Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#2f2e41"
    />
    <path
      d="M697.48116,773.0434l-33.88394-4.75008a6.681,6.681,0,0,1-5.518-8.37415v0a6.681,6.681,0,0,1,5.49045-4.85446l6.02106-.86971,19.0382-11.62728,15.74819,4.19952,4.66955,13.78064A10.36821,10.36821,0,0,1,697.48116,773.0434Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#2f2e41"
    />
    <path
      d="M830.36107,773.0434l-33.88394-4.75008a6.681,6.681,0,0,1-5.518-8.37415v0a6.681,6.681,0,0,1,5.49044-4.85446l6.02107-.86971,19.03819-11.62728,15.7482,4.19952,4.66955,13.78064A10.36821,10.36821,0,0,1,830.36107,773.0434Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#2f2e41"
    />
    <path
      d="M770.33852,429.28212c-6.1334-13.48063-16.72329-18.70312-34.40039-10.79228l2.38344-17.57787a34.154,34.154,0,0,1,24.59723-28.2893h0Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#7d1111"
    />
    <path
      d="M761.42964,315.60989s-4.24452-33.03273,30.3879-22.20053c0,0,24.73469-4.53845,32.84616,26.40086l8.21341,32.432-4.0028-2.1787-1.86613,4.07915-6.48641,1.64268-2.77569-5.50923-1.34275,6.69027-53.12722,5.29634s23.79461-14.072,24.17193-39.29232L782.91567,327.76Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#2f2e41"
    />
    <circle cx="656.42221" cy="166.87979" r="13.35886" fill="#2f2e41" />
    <path
      d="M806.53392,290.46235a13.34835,13.34835,0,0,1,13.35254-12.48574c-.27585-.035-.54923-.07754-.83051-.09563a13.35886,13.35886,0,0,0-1.71422,26.66268c.28128.01808.55785.01089.83592.01151A13.34835,13.34835,0,0,1,806.53392,290.46235Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#2f2e41"
    />
    <path
      d="M287.67678,415.521v26.53177a.66776.66776,0,0,1-.66753.668H241.96947a.56216.56216,0,0,1-.09573-.00745.66735.66735,0,0,1-.57225-.66053V415.521a.66776.66776,0,0,1,.66753-.668H287.0088a.66775.66775,0,0,1,.668.66753Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#e6e6e6"
    />
    <path
      d="M623.67678,415.521v26.53177a.66776.66776,0,0,1-.66753.668H577.96947a.56216.56216,0,0,1-.09573-.00745.66735.66735,0,0,1-.57225-.66053V415.521a.66776.66776,0,0,1,.66753-.668H623.0088a.66775.66775,0,0,1,.668.66753Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#7d1111"
    />
    <path
      d="M948.67678,415.521v26.53177a.66776.66776,0,0,1-.66753.668H902.96947a.56216.56216,0,0,1-.09573-.00745.66735.66735,0,0,1-.57225-.66053V415.521a.66776.66776,0,0,1,.66753-.668H948.0088a.66775.66775,0,0,1,.668.66753Z"
      transform="translate(-163.87686 -126.85301)"
      fill="#e6e6e6"
    />
  </svg>
)
