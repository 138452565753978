import React from "react"
import Layout from "../../components/layout/Layout"
import Illustration from "../../components/404-illustration/index"
import "./index.css"

export default function ErrorPage() {
  return (
    <Layout>
      <div>
        <Illustration height="70vh" />
      </div>
      <p className="text-404">
        Oops! Looks like the page you were looking for does not exist.
      </p>
      <p className="text-404">
        But do look around as there is{" "}
        <a className="link-404" href="/">
          plenty to be seen
        </a>
        .
      </p>
    </Layout>
  )
}
